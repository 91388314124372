<template>
<div>
    <div class="header">
        <el-page-header @back="goBack" :content="name"></el-page-header>
    </div>
    <div>
        <el-image fit="fit" style="width: 90vw; height: 40vh" src="https://thesongof404.top:3000/blog/login_bg.png"></el-image>
    </div>
    <div>
        <router-link to="/Sup">
            <p @click="changename('登录')">登录</p>
        </router-link>
        <router-link to="/Sin">
            <p @click="changename('注册')">注册</p>
        </router-link>
    </div>
    <div>
        <hr class="mainhr" />
    </div>
    <div class="views">
        <router-view></router-view>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            name: "登录",
        };
    },
    methods: {
        goBack() {
            // this.$router.push("/today");
            this.$router.back(-1)
            // console.log("go back");
        },
        changename(event) {
            this.name = event;
        },
    },
};
</script>

<style scoped>
.header {
    margin: 1rem;
}

a {
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    text-decoration: none;
    color: rgb(0, 0, 0);
}

p {
    display: inline;
    margin: 0 18vw;
    font-weight: 700;
    font-size: 1rem;
}

.mainhr {
    border: none;
    height: 2px;
    background-color: rgb(229, 230, 235);
    width: 90vw;
    margin: 2px auto;
}

.views {
    width: 90vw;
    margin: 0 auto;
}
</style>
